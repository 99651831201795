<template>
  <main-layout>
    <div class="pvp-container">
      <h1 class="title">{{ $t('video.page_title') }}</h1>

      <div class="videos-page__container">
        <section class="games-filter-section">
          <games-filter v-model="gamesIds" @input="onGameChange">
            <template slot="title">{{ $t('games.filter') }}</template>
          </games-filter>
        </section>

        <section class="videos-section">
          <loader v-if="loadingState.videosList" />
          <ul v-else-if="videoList.length" class="videos-list">
            <li
              v-for="video in videoList"
              :key="video.id"
              class="videos-list__item"
            >
              <MainCard
                :data="video"
                :link-to="`/videos/${video.id}`"
              />
            </li>
          </ul>
          <div v-else class="placeholder">
            <icon class="placeholder-icon" name="pvp-swords" />
            {{ $t('video.page_nofiltered') }}
          </div>
        </section>
      </div>
      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        :disabled="loadingState.videosList"
        @onchange="setPage"
      />
    </div>

    <p slot="seo-text">{{ seoText }}</p>
  </main-layout>
</template>

<script>
import MainCard from '@components/v2/cards/MainCard.vue';
import GamesFilter from '@components/GameComponents/GamesFilter.vue';
import Icon from '@components/v2/utils/Icon.vue';

import { fetchVideos } from '@src/shared/api/video';

export default {
  components: {
    MainCard,
    GamesFilter,
    Icon,
  },
  page() {
    return {
      title: this.$t('video.page_meta_title'),
      meta: [
        {
          description: this.$t('video.page_meta_description', {
            host: window.location.host.toLocaleUpperCase(),
          }),
        },
      ],
    };
  },
  data() {
    const gamesIds =
      this.$route.query?.games?.split(',')?.map((id) => Number(id)) ||
      [];
    const currentPage = Number(this.$route.query?.page) || 1;
    return {
      loadingState: {
        ...this.loadingState,
        videosList: true,
      },
      videoList: [],

      // Pagination
      gamesIds,
      currentPage,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],
    };
  },
  computed: {
    seoText() {
      return this.$t('seo.video', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },
  },
  created() {
    this.setQuery();
  },
  methods: {
    setPage({ page, perPage }) {
      this.currentPage = page;
      this.perPage = perPage;
      this.setQuery();
    },

    reloadVideos() {
      this.loadingState.videosList = true;
      return this.loadVideosListData();
    },

    async loadVideosListData() {
      const responseData = await fetchVideos({
        pageNum: this.currentPage - 1,
        pageSize: this.perPage,
        game: this.gamesIds.length ? this.gamesIds : null,
      });

      this.videoList = responseData.items;
      this.total = responseData.pagen.totalCount;

      this.loadingState.videosList = false;

      window.scrollTo({
        top: 0,
        behaviour: 'smooth',
      });
    },

    setQuery() {
      const query = {
        ...this.$route.query,
        page: this.currentPage,
      };

      if (this.gamesIds.length) {
        query.games = this.gamesIds.join(',');
      } else {
        query.games = undefined;
      }

      this.$router.replace({ query }).catch(() => ({}));
      this.reloadVideos();
    },

    onGameChange() {
      this.currentPage = 1;
      this.setQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.title {
  margin-top: 30px;
}

.videos-section {
  margin-top: 50px;
}

.videos-list {
  width: 100%;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 24px;

  @include min-tablet() {
    grid-template-columns: repeat(2, 1fr);
  }

  @include min-laptop() {
    grid-template-columns: repeat(3, 1fr);
  }
}

.videos-list__item {
  list-style-type: none;
}

.load-more-box {
  margin-top: 20px;
}

.pagination-box {
  background-color: $dark-background;
  border-radius: 0;
  box-shadow: $default-box-shadow;
  background-color: $dark-background;
  border-radius: 0;
  box-shadow: $default-box-shadow;
  display: flex;
  margin-top: 20px;
  align-items: center;
  padding: 16px;
  border: 1px solid rgba(white, 0.1);

  .pagination {
    margin: 0;
    font-size: 0.75rem;
  }

  .controls {
    display: flex;
    align-items: center;
    margin-left: auto;

    .label {
      color: #838488;
    }

    .pagination {
      margin-left: 9px;
    }
  }
}

.videos-page__container {
  margin-top: 30px;
}

.carousel-block {
  height: auto;
  @include min-tablet() {
    height: 525px;
  }
}

.placeholder {
  background-color: $dark-background;
  border-radius: 4px;
  box-shadow: $default-box-shadow;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  @include max-tablet() {
    padding: 12px;
    font-size: 18px;
  }

  &-icon {
    font-size: 30px;
    margin-bottom: 12px;
  }
}

.pagination {
  margin-top: 20px;
}
</style>
