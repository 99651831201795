<template>
  <main-layout class="padded" :is-loading="isLoading">
    <div :key="id" class="pvp-container video-item-page">
      <BaseLink
        icon-left="arrow-left"
        :to="{ name: 'videos', query }"
      >
        {{ $t('video.page_toVideoList') }}
      </BaseLink>

      <div v-if="videoPost" class="post">
        <div class="post-wrapper">
          <h1 class="post-title">{{ videoPost.name }}</h1>

          <div class="post-info">
            <game-tag :id="videoPost.idGame" />
            <div class="post-date">
              {{ getDate(videoPost.publishTimeFull.ts) }}
            </div>
          </div>

          <div class="post-container">
            <div v-if="videoPost.isLive" class="stream">LIVE</div>
            <iframe
              v-if="videoPost.src"
              :src="videoPost.src"
              frameborder="0"
              allowscriptaccess="always"
              allowfullscreen
              class="post-iframe"
            />
            <img
              v-else
              :src="videoPost.imgPreview"
              class="post-image"
            />
          </div>

          <p v-if="videoPost.detailText" class="post-text">
            {{ videoPost.detailText }}
          </p>

          <BaseSocialLinks
            class="share"
            :share-title="videoPost.name"
            :share-img="videoPost.imgPreview"
          />
        </div>
      </div>

      <div v-if="videos.length" class="cards">
        <h2 class="cards-title">{{ $t('video.page_also') }}</h2>
        <BaseLink
          :to="{ name: 'videos' }"
          icon-right="arrow-right"
          class="cards-link"
        >
          {{ $t('video.all') }}
        </BaseLink>
        <div class="video-list">
          <MainCard
            v-for="video in videos"
            :key="video.id"
            :data="video"
            :link-to="`/videos/${video.id}`"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainCard from '@components/v2/cards/MainCard.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

import { fetchVideoPost, fetchVideos } from '@src/shared/api/video';
import { getDateString } from '@utils/time.js';

export default {
  components: {
    BaseSocialLinks,
    BaseLink,
    MainCard,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.query = from?.query || {};
    });
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    videoPost: null,
    videos: [],
    query: {},
  }),
  watch: {
    id: {
      handler: 'loadVideoPost',
      immediate: true,
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behaviour: 'smooth',
      });
    },

    getDate(seconds) {
      return getDateString(seconds, 'ru');
    },

    async loadVideoPost() {
      this.isLoading = true;
      this.videoPost = (await fetchVideoPost(this.id))?.item;

      await this.loadVideoCards();
      this.scrollTop();

      this.isLoading = false;
    },

    async loadVideoCards() {
      const game = [this.videoItem?.idGame];
      const responseData = await fetchVideos({
        pageSize: 3,
        game,
      });

      this.videos = responseData.items;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.base-link:hover {
  text-decoration: none;
}

.post {
  margin-top: 26px;
  padding: 30px 12px;
  border-radius: var(--border-radius--medium);
  box-shadow: 0 2px 12px 0 rgba(black, 0.1);
  border: 1px solid rgba(white, 0.05);
  background-color: rgba($dark-two, 0.41);

  &-title {
    font-size: 24px;
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
  }

  &-date {
    margin-left: 14px;
    color: rgba(white, 0.6);
    font-size: 13px;
  }

  &-container {
    margin-top: 20px;
    position: relative;
  }

  &-iframe {
    border: none;
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
  }

  &-image {
    display: block;
    width: 100%;
  }

  &-text {
    margin-top: 30px;
    color: rgba(white, 0.8);
    font-size: 17px;
    line-height: 1.53;
  }
}

.share {
  margin-top: 20px;
}

.stream {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0 4px 0 14px;
  font-size: 13px;
  background-color: rgba(39, 37, 50, 0.8);

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $orangey-red-three;
  }
}

.cards {
  margin-top: 30px;

  &-link,
  &-title {
    display: inline-block;
    vertical-align: middle;
    font-size: 17px;
    line-height: normal;
  }

  &-title {
    font-weight: bold;
  }

  &-link {
    margin-left: 20px;
  }
}

.video-list {
  margin-top: 12px;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 24px;

  @include min-tablet() {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
